import moment from 'moment';
import { SET_TASKS } from '../redux/actionTypes';
import { get, handleResponse, post } from './network';
import { getCookie } from './utils';
import _ from 'lodash';
import { cleanRanking, unpinTask } from '../redux/actions';

export const createTask = async (project, iteration, story, task, state, responsibles) => {
  let query;
  if (story) {
    query = `clients/0/projects/${project.id}/iterations/${iteration.id}/stories/${story.id}/tasks`;
  } else {
    query = `clients/0/projects/${project.id}/iterations/${iteration.id}/tasks`;
  }

  const body = {
    name: task.name,
    state: state.id ?? 0,
    responsibles: responsibles.map(r => r.id),
  };

  story?.id ? (body.storyid = story.id) : (body.iterationid = iteration.id);

  const res = await post(query, body);
  const { message: taskId } = await handleResponse(res);
  return taskId;
};

export const getTasksPath = values => {
  const { project, iteration, story } = values;
  if (story?.id) return `clients/0/projects/${project.id}/iterations/${iteration.id}/stories/${story.id}/tasks`;

  return `clients/0/projects/${project.id}/iterations/${iteration.id}/tasks`;
};

export const getTask = async taskId => {
  try {
    const res = await get(`clients/0/projects/0/iterations/0/stories/0?id=${taskId}`);
    const { message: tasks } = await handleResponse(res);
    const task = tasks[0] ?? Promise.reject(new Error('Task not found'));

    return task;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchActiveTasks = async ({ history }) => {
  const userId = getCookie('userId');
  try {
    const response = await get(`users/${userId}/active-tasks`);
    const { message: tasks } = await handleResponse(response, { history });
    return tasks;
  } catch (e) {
    return Promise.reject(e);
  }
};

/*
  This function will be executed at most once per day.
  It will clean any task in ranking when it is not in the active tasks list.
*/
export const rankingGarbageCollectorMiddleware = store => next => action => {
  if (action.type !== SET_TASKS) return next(action);
  const {
    ranking: { lastUpdated, tasks: ranking, pinned },
  } = store.getState();
  if (lastUpdated && moment().isSame(moment(lastUpdated), 'day')) return next(action);
  const result = next(action);
  const ids = result.tasks.map(({ id }) => id);
  const filteredRankingTasks = _.pickBy(ranking, (task, id) => ids.includes(parseInt(id)));
  store.dispatch(cleanRanking(filteredRankingTasks));
  const notActivePinnedTaskIds = _.difference(pinned, ids);
  notActivePinnedTaskIds.forEach(id => store.dispatch(unpinTask(id)));
  return result;
};
