import { IconButton, Menu, MenuItem } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCriteria } from '../../../redux/actions';
import { TextInput } from '../TextInput';
import { criteria } from './criteria';
import { FilteredTaskList } from './FilteredTaskList';
import { TaskListSkeleton } from './TaskListSkeleton';
import { useStyles } from './useStyles';

export default function TaskList({ handleClickTask, values, handleChange, loading }) {
  const classes = useStyles();
  const tasks = useSelector(state => state.tasks.tasks);
  const dispatch = useDispatch();
  const { tasks: ranking, selectedCriteria } = useSelector(state => state.ranking);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickFilterMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = (e, index) => {
    dispatch(setCriteria(index));
    handleCloseFilterMenu();
  };

  return (
    <>
      <div className={classes.selectTaskHeader}>
        <TextInput
          name="taskFilter"
          style={{ marginTop: 10, width: 260 }}
          label="Filter"
          value={values.taskFilter}
          handleChange={handleChange}
        />
        <div>
          <IconButton aria-controls="filter-menu" aria-haspopup="true" onClick={handleClickFilterMenu}>
            <FilterListIcon />
          </IconButton>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseFilterMenu}
          >
            {criteria.map((c, i) => (
              <MenuItem key={i} selected={selectedCriteria === i} onClick={e => handleClickMenuItem(e, i)}>
                {c.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      {loading ? (
        <TaskListSkeleton />
      ) : (
        <FilteredTaskList
          tasks={tasks}
          ranking={ranking}
          selectedCriteria={selectedCriteria}
          taskFilter={values.taskFilter}
          handleClickTask={handleClickTask}
        />
      )}
    </>
  );
}
